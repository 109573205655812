import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import Switch from '../../components/common/form/switch';
import StackedHorizontalBarChart from '../../components/topics/charts/stackedHorizontalBarChart';
import ScalableTimeChart from '../../components/topics/charts/scalableTimeChart';
import Card from '../../components/common/card';
import VotesMap from '../../components/votes/votesMap';
import {
    VOTE_CHART_MODES,
    getGenderGroupData,
    getAgeGroupData,
    getLocationGroupData,
    getSourceStackData,
    getSourceGroupData
} from '../../helpers/voteDataHelper';
import memoizeOne from 'memoize-one';
import { initChartSizeConfig, updateChartWidth } from '../../helpers/topicsHelper';
import { STACKING_OPTIONS } from '../../helpers/graphsService';
import './css/topicDetailsGraphs.css';
import FeaturesContext from "../contexts/featuresContext";
import { ENTITY_FEATURES } from "hollerlive-shared/constants";
import { COMPARATORS } from "../../helpers/featuresHelper";
import NoPermissionLabel from '../../components/common/noPermissionLabel';
import messages from '../../helpers/messages';
import i18n from '../../i18n';

export class TopicDetailsGraphs extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            maxChartWidth: null,
            halfChartWidth: null,
            shouldUpdateChartsWidth: false,
            allLocationsShow: false,
            votesMapShow: true,
            votesByGenderChartMode: VOTE_CHART_MODES.STACK,
            votesByAgeChartMode: VOTE_CHART_MODES.STACK,
            votesByLocationChartMode: VOTE_CHART_MODES.STACK,
            votesBySourceChartMode: VOTE_CHART_MODES.STACK,
            shouldForceResize: false,
            timeScaleLabel: null,
            t: i18n.t.bind(i18n)
        };

        this.handleResize = this.handleResize.bind(this);
        this.handleVotesByTimeTimeScaleValueChange = this.handleVotesByTimeTimeScaleValueChange.bind(this);
        this.toggleVotesBy = this.toggleVotesBy.bind(this);
        this.getVotesDataChunkViews = this.getVotesDataChunkViews.bind(this);
        this.chartsSizesConfiguration = null;
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);        
        this.chartsSizesConfiguration = initChartSizeConfig(this.chartsContainerDom, 'card');
        
        const { halfChartWidth, maxChartWidth } = this.chartsSizesConfiguration;

        this.setState({ halfChartWidth, maxChartWidth });
    }
    
    componentDidUpdate(prevProps, prevState) {
        const { dateFrom, dateTo, voterFilters } = this.props;

        if (this.state.shouldUpdateChartsWidth) {
            this.setChartsWidth();
        }

        if ((prevProps.dateFrom !== dateFrom) || (prevProps.dateTo !== dateTo) ||
          !isEqual(prevProps.voterFilters, voterFilters)) {
            this.setState({ shouldForceResize: true, timeScaleLabel: null });
        } else if (prevState.shouldForceResize) {
            this.setState({ shouldForceResize: false });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize() {
        this.setState({
            maxChartWidth: null,
            halfChartWidth: null,
            shouldUpdateChartsWidth: true
        });
    }

    handleVotesByTimeTimeScaleValueChange(selectedTimeScaleName) {
        this.setState({ timeScaleLabel: selectedTimeScaleName });
    }

    setChartsWidth(){
        updateChartWidth(this.chartsSizesConfiguration);
        const { halfChartWidth, maxChartWidth } = this.chartsSizesConfiguration;

        this.setState({ halfChartWidth, maxChartWidth, shouldUpdateChartsWidth: false });
    }
    
    toggleShowAllLocations(ev){
        if(ev){
            ev.preventDefault();
        }
        this.setState({ allLocationsShow: !this.state.allLocationsShow });
    }

    toggleShowVotesMap(ev){
        if(ev){
            ev.preventDefault();
        }
        this.setState({ votesMapShow: !this.state.votesMapShow });
    }
    
    toggleVotesBy(chartModeKey) {
        if(this.state[chartModeKey] === VOTE_CHART_MODES.STACK) {
            this.setState({[chartModeKey]: VOTE_CHART_MODES.MEAN});
        } else {
            this.setState({[chartModeKey]: VOTE_CHART_MODES.STACK});
        }
    }

    getVotesDataChunkViews(filteredVotesForPeriod) {
        return {
			votesCombinedByGenderAndSorted:
				filteredVotesForPeriod.length > 0 ? this._getVotesByGender(filteredVotesForPeriod) : [],
			votesCombinedByAgeAndSorted:
				filteredVotesForPeriod.length > 0 ? this._getVotesByAge(filteredVotesForPeriod) : [],
			votesCombinedByCityAndSorted:
				filteredVotesForPeriod.length > 0 ? this._getVotesByCity(filteredVotesForPeriod) : [],
			votesWithSources:
				filteredVotesForPeriod.length > 0 ? this._getVotesWithSources(filteredVotesForPeriod) : [],
			votesCombinedBySourceAndSorted:
				filteredVotesForPeriod.length > 0 ? this._getVotesBySource(filteredVotesForPeriod) : []
		};
    }

    _getVotesByGender = memoizeOne(rawVotes =>
        getGenderGroupData(rawVotes).sort((a, b) => b.votesCount - a.votesCount)
    );

    _getVotesByAge = memoizeOne(rawVotes =>
        getAgeGroupData(rawVotes).sort((a, b) => b.votesCount - a.votesCount)
    );

    _getVotesByCity = memoizeOne(rawVotes =>
        getLocationGroupData(rawVotes).sort((a, b) => b.votesCount - a.votesCount)
    );
    
    _getVotesWithSources = memoizeOne(rawVotes => getSourceStackData(rawVotes));

    _getVotesBySource = memoizeOne(rawVotes =>
        getSourceGroupData(rawVotes).sort((a, b) => b.votesCount - a.votesCount)
    );
    
    render() {
        const t = this.state.t;
        const filteredVotesForPeriod = [];
        
        for(const vote of this.props.votes) {
            const newVote = { ...vote }
            
            if(newVote.gender === 'male') {
                newVote.gender = t('label_gender_male');
            } else if (newVote.gender === 'female') {
                newVote.gender = t('label_gender_female');
            } else {
                newVote.gender = t('label_gender_undefined');
            }

            if(!newVote.ageGroup) {
                newVote.ageGroup = t('label_age_group_undefined');
            }

            filteredVotesForPeriod.push(newVote);
        }

		const {
			votesCombinedByGenderAndSorted,
			votesCombinedByAgeAndSorted,
			votesCombinedByCityAndSorted,
			votesWithSources,
			votesCombinedBySourceAndSorted
		} = this.getVotesDataChunkViews(filteredVotesForPeriod);
        
        const chartMaxWidth = this.state.maxChartWidth;
        const chartHalfWidth = this.state.halfChartWidth;
        const chartLocationHeight = this.state.allLocationsShow ? votesCombinedByCityAndSorted.length * 30 : 300;
        const chartSourceHeight = Math.max(votesCombinedBySourceAndSorted.length * 30, 300);
        
        return (
            <div className="topic-details-graphs">
                <div id="vis-scatter" />
                <div id="vis-tooltip" className="vg-tooltip" />
                
                <div className="charts-container row-fluid clearfix export-pdf-votes-analysis-container" ref={chartsContainer => this.chartsContainerDom = chartsContainer}>
                    <div className="col-md-12 col-xs-12">
                        <Card title={t('topic_analytics_chart_votes_by_time_title')} hoffset={false} className="pdf-export-votes-graph-wrapper">
                            <ScalableTimeChart
                                type="line"
                                data={filteredVotesForPeriod}
                                dataFieldName="vote"
                                chartYAxisTitle={t('topic_analytics_chart_votes_by_time_yaxis')}
                                chartXAxisTitle={t('topic_analytics_chart_votes_by_time_xaxis')}
                                className="export-pdf-chart-wrapper"
                                showLegend={true}
                                width={chartMaxWidth}
                                dateFrom={this.props.dateFrom} 
                                dateTo={this.props.dateTo}
                                onTimeScaleChange={this.handleVotesByTimeTimeScaleValueChange}
                                showScaleSelector={true}
                            />
                        </Card>
                    </div>

                    <div className="col-md-12 col-xs-12">
                        <Card title={t('topic_analytics_chart_votes_by_volume_title')} hoffset={false} className="pdf-export-votes-graph-wrapper">
                            <ScalableTimeChart
                                type="bar"
                                data={filteredVotesForPeriod}
                                dataFieldName="vote"
                                stackFieldOptions={[STACKING_OPTIONS.sentiment, STACKING_OPTIONS.ageGroups, STACKING_OPTIONS.gender]}
                                chartYAxisTitle={t('topic_analytics_chart_votes_by_volume_yaxis')}
                                chartXAxisTitle={t('topic_analytics_chart_votes_by_volume_xaxis')}
                                className="export-pdf-chart-wrapper"
                                showLegend={true}
                                width={chartMaxWidth}
                                dateFrom={this.props.dateFrom} 
                                dateTo={this.props.dateTo}
                                timeScaleLabel={this.state.timeScaleLabel}
                            />
                        </Card>
                    </div>
                    
                    <div className="col-md-6 col-xs-12">
                        <Card title={t('topic_analytics_chart_votes_by_gender_title')} hoffset={false} className="pdf-export-votes-graph-wrapper">
                            <div className='chart-control-options export-pdf-chart-control-options'>
                                <span>{t('scalable_time_chart_showing_as')}&nbsp;</span>
                                <Switch
                                    value={this.state.votesByGenderChartMode === VOTE_CHART_MODES.STACK}
                                    onChange={() => this.toggleVotesBy('votesByGenderChartMode')}
                                    offLabel={t('scalable_time_chart_label_mean')}
                                    onLabel={t('scalable_time_chart_label_stack')} />
                            </div>
                            {this.state.votesByGenderChartMode === VOTE_CHART_MODES.STACK ?
                                <StackedHorizontalBarChart
                                    key='votesByGenderChartStack'
                                    data={filteredVotesForPeriod}
                                    dataFieldName="gender"
                                    stackFieldOptions={[STACKING_OPTIONS.sentiment, STACKING_OPTIONS.ageGroups]}
                                    chartYAxisTitle={t('topic_analytics_chart_votes_by_gender_yaxis')}
                                    chartXAxisTitle={t('topic_analytics_chart_votes_by_gender_xaxis')}
                                    className="export-pdf-chart-wrapper"
                                    showLegend={true}
                                    width={chartHalfWidth} /> :
                                <StackedHorizontalBarChart
                                    key='votesByGenderChartMean'
                                    data={votesCombinedByGenderAndSorted}
                                    dataFieldName='gender'
                                    aggregationFieldName='votesCount'
                                    stackFieldOptions={[STACKING_OPTIONS.sentiment]}
                                    chartYAxisTitle={t('topic_analytics_chart_votes_by_gender_yaxis')}
                                    chartXAxisTitle={t('topic_analytics_chart_votes_by_gender_xaxis')}
                                    className='export-pdf-chart-wrapper'
                                    showLegend={true}
                                    width={chartHalfWidth}
                                    forceResize={this.state.shouldForceResize} />}
                            
                        </Card>
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <Card title={t('topic_analytics_chart_votes_by_age_title')} hoffset={false} className="pdf-export-votes-graph-wrapper">
                            <div className='chart-control-options export-pdf-chart-control-options'>
                                <span>{t('scalable_time_chart_showing_as')}&nbsp;</span>
                                <Switch
                                    value={this.state.votesByAgeChartMode === VOTE_CHART_MODES.STACK}
                                    onChange={() => this.toggleVotesBy('votesByAgeChartMode')}
                                    offLabel={t('scalable_time_chart_label_mean')}
                                    onLabel={t('scalable_time_chart_label_stack')} />
                            </div>
                            {this.state.votesByAgeChartMode === VOTE_CHART_MODES.STACK ?
                                <StackedHorizontalBarChart
                                    key='votesByAgeChartStack'
                                    data={filteredVotesForPeriod}
                                    dataFieldName="ageGroup"
                                    stackFieldOptions={[STACKING_OPTIONS.sentiment, STACKING_OPTIONS.gender]}
                                    chartYAxisTitle={t('topic_analytics_chart_votes_by_age_yaxis')}
                                    chartXAxisTitle={t('topic_analytics_chart_votes_by_age_xaxis')}
                                    className="export-pdf-chart-wrapper"
                                    showLegend={true}
                                    width={chartHalfWidth} /> :
                                <StackedHorizontalBarChart
                                    key='votesByAgeChartMean'
                                    data={votesCombinedByAgeAndSorted}
                                    dataFieldName='ageGroup'
                                    aggregationFieldName='votesCount'
                                    stackFieldOptions={[STACKING_OPTIONS.sentiment]}
                                    chartYAxisTitle={t('topic_analytics_chart_votes_by_age_yaxis')}
                                    chartXAxisTitle={t('topic_analytics_chart_votes_by_age_xaxis')}
                                    className='export-pdf-chart-wrapper'
                                    showLegend={true}
                                    width={chartHalfWidth}
                                    forceResize={this.state.shouldForceResize} />}
                        </Card>
                    </div>
    
                    { this.state.votesMapShow &&
                    <div className="col-md-12 col-xs-12">
                        <Card title={t('topic_analytics_chart_votes_map_title')} hoffset={ false }>
                            <VotesMap data={ votesCombinedByCityAndSorted }/>
                        </Card>
                    </div>
                    }
                    
                    <div className="col-md-12 col-xs-12">
                        <Card title={t('topic_analytics_chart_votes_by_location_title')} hoffset={false} className="pdf-export-votes-graph-wrapper">
                            <div className='chart-control-options export-pdf-chart-control-options'>
                                <span>{t('scalable_time_chart_showing_as')}&nbsp;</span>
                                <Switch
                                    value={this.state.votesByLocationChartMode === VOTE_CHART_MODES.STACK}
                                    onChange={() => this.toggleVotesBy('votesByLocationChartMode')}
                                    offLabel={t('scalable_time_chart_label_mean')}
                                    onLabel={t('scalable_time_chart_label_stack')} />
                            </div>
                            {this.state.votesByLocationChartMode === VOTE_CHART_MODES.STACK ?
                                <StackedHorizontalBarChart
                                    key='votesByLocationChartStack'
                                    data={filteredVotesForPeriod}
                                    dataFieldName='city'
                                    stackFieldOptions={[STACKING_OPTIONS.sentiment, STACKING_OPTIONS.gender, STACKING_OPTIONS.ageGroups]}
                                    chartYAxisTitle={t('topic_analytics_chart_votes_by_location_yaxis')}
                                    chartXAxisTitle={t('topic_analytics_chart_votes_by_location_xaxis')}
                                    className='export-pdf-chart-wrapper'
                                    showLegend={true} 
                                    width={chartMaxWidth}
                                    height={chartLocationHeight} /> :
                                <StackedHorizontalBarChart
                                    key='votesByLocationChartMean'
                                    data={this.state.allLocationsShow ? votesCombinedByCityAndSorted : votesCombinedByCityAndSorted.slice(0, 10)}
                                    width={chartMaxWidth}
                                    height={chartLocationHeight}
                                    dataFieldName="city"
                                    stackFieldOptions={[STACKING_OPTIONS.sentiment]}
                                    aggregationFieldName="votesCount"
                                    chartYAxisTitle={t('topic_analytics_chart_votes_by_location_yaxis')}
                                    chartXAxisTitle={t('topic_analytics_chart_votes_by_location_xaxis')}
                                    className="export-pdf-chart-wrapper"
                                    forceResize={this.state.shouldForceResize}
                                    showLegend={true}
                                />}
                        </Card>

                        {votesCombinedByCityAndSorted && votesCombinedByCityAndSorted.length > 0 &&
                        <div className="btn-group">
                            {!this.state.allLocationsShow &&
                                <button type="button" className="btn btn-outline load-more" onClick={this.toggleShowAllLocations.bind(this)} disabled={(votesCombinedByCityAndSorted.length - 10) <= 0}>
                                    {t('topic_analytics_chart_votes_by_location_show_more')} ({(votesCombinedByCityAndSorted.length - 10) > 0 ? votesCombinedByCityAndSorted.length - 10 : 0})
                                </button>
                            }

                            {this.state.allLocationsShow &&
                                <button type="button" className="btn btn-outline load-more" onClick={this.toggleShowAllLocations.bind(this)}>
                                    {t('topic_analytics_chart_votes_by_location_show_less')}
                                </button>
                            }

                            <button type="button" className="btn btn-outline load-more" onClick={this.toggleShowVotesMap.bind(this)}>
                                {!this.state.votesMapShow ? t('topic_analytics_chart_votes_by_location_show_votes_map') : t('topic_analytics_chart_votes_by_location_hide_votes_map')}
                            </button>
                        </div>
                        }
                    </div>
    
    
                    <div className="col-md-12 col-xs-12">
                        <FeaturesContext
                            requiredFeatures={[{
                                name: ENTITY_FEATURES.HAS_SOURCES_CHART,
                                compare: COMPARATORS.IS_TRUE
                            }]}
                            renderOtherwise={
                                <Card title={t('topic_analytics_chart_votes_by_source_title')} hoffset={false}>
                                    <NoPermissionLabel mode="panel" message={messages.permissions.features.noCustomSources} />
                                </Card>
                            }
                        >
                            <Card title={t('topic_analytics_chart_votes_by_source_title')} hoffset={false} className="pdf-export-votes-graph-wrapper">                                
                                <FeaturesContext
                                    hideIfNoPermission={true}
                                    requiredFeatures={[{
                                        name: ENTITY_FEATURES.HAS_SOURCES_CHART,
                                        compare: COMPARATORS.IS_TRUE
                                    }]}>
                                    
                                    <div className='chart-control-options export-pdf-chart-control-options'>
                                        <span>{t('scalable_time_chart_showing_as')}&nbsp;</span>
                                        <Switch
                                            value={this.state.votesBySourceChartMode === VOTE_CHART_MODES.STACK}
                                            onChange={() => this.toggleVotesBy('votesBySourceChartMode')}
                                            offLabel={t('scalable_time_chart_label_mean')}
                                            onLabel={t('scalable_time_chart_label_stack')} />
                                    </div>
                                    {this.state.votesBySourceChartMode === VOTE_CHART_MODES.STACK ?
                                        <StackedHorizontalBarChart
                                            key='votesBySourceChartStack'
                                            data={votesWithSources}
                                            dataFieldName='sources'
                                            stackFieldOptions={[STACKING_OPTIONS.sentiment, STACKING_OPTIONS.gender, STACKING_OPTIONS.ageGroups]}
                                            chartYAxisTitle={t('topic_analytics_chart_votes_by_source_yaxis')}
                                            chartYAxisLabelLimit={100}
                                            chartXAxisTitle={t('topic_analytics_chart_votes_by_source_xaxis')}
                                            className='export-pdf-chart-wrapper'
                                            showLegend={true}
                                            width={chartMaxWidth}
                                            height={chartSourceHeight}
                                        /> 
                                        :
                                        <StackedHorizontalBarChart
                                            key='votesBySourceChartMean'
                                            data={votesCombinedBySourceAndSorted}
                                            width={chartMaxWidth}
                                            height={chartSourceHeight}
                                            dataFieldName="source"
                                            stackFieldOptions={[STACKING_OPTIONS.sentiment]}
                                            aggregationFieldName="votesCount"
                                            chartYAxisTitle={t('topic_analytics_chart_votes_by_source_yaxis')}
                                            chartYAxisLabelLimit={100}
                                            chartXAxisTitle={t('topic_analytics_chart_votes_by_source_xaxis')}
                                            className="export-pdf-chart-wrapper"
                                            forceResize={this.state.shouldForceResize}
                                            showLegend={true}
                                        />}
                                        
                                </FeaturesContext>
                                
                            </Card>
                        </FeaturesContext>
                    </div>
                </div>
            </div>
        );
    }
}

TopicDetailsGraphs.propTypes = {
    votes: PropTypes.array,
    questionnaire: PropTypes.array,
    questionnaireTotal: PropTypes.number,
    dateFrom: PropTypes.any,
    dateTo: PropTypes.any,
    periodIndex: PropTypes.number,
    voterFilters: PropTypes.object,
};

export default TopicDetailsGraphs;
