/* eslint-disable no-irregular-whitespace */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Icon from 'react-feather';
import { Field, ErrorMessage, FieldArray } from 'formik';

import * as Icons from 'react-feather';
import AnswerItem from './answerItem';
import Card from '../../common/card';
import BSFormControl from '../../common/form/bsFormControl';
import Toggle from '../../common/form/toggle';
import RangeSlider from '../../common/form/rangeSliderSelector';
import ButtonGroup from '../../common/form/buttonGroup';
import Logger from '../../../helpers/logger';
import { ANSWER_TYPES, ANSWERS_STRUCTURE, EMPTY_ANSWER_TEMPLATE } from '../../../helpers/topicBuilderHelper';
import { CONFIRM_MODAL } from '../../../helpers/modalTypes';
import formValidators from '../../../helpers/formValidators';
import VoteRangeLabel from '../common/voteRangeLabel';
import FeaturesContext from "../../../scenes/contexts/featuresContext";
import { ENTITY_FEATURES } from "hollerlive-shared/constants";
import { COMPARATORS } from "../../../helpers/featuresHelper";
import NoPermissionLabel from '../../common/noPermissionLabel';
import messages from '../../../helpers/messages';
import MediaDnD from '../../common/form/MediaDnD';
import i18n from '../../../i18n';
import { processQuestionImage } from '../../../helpers/imageUtils';

class QuestionItem extends Component {
	constructor(props) {
		super(props);
		const { question } = props;
		const questionInitialState = this.getQuestionInitialState(question);

		const initialState = Object.assign({}, questionInitialState, {
			confirmAnswersTypeIndex: null,
			answerTypeSwitchConfirmVisible: false,
			isOutsideCollapsed: this.props.isCollapsedAll,
			questionReloaded: false,
			imgPreview: question.image
		});

		this.state = {
			...initialState,
			showDuplicateOptions: false,
			t: i18n.t.bind(i18n),
		};

		this.toggleVoteInRangeEnabled = this.toggleVoteInRangeEnabled.bind(this);
		this.toggleMultipleOptionsEnable = this.toggleMultipleOptionsEnable.bind(this);
		this.onAnswersTypeSwitch = this.onAnswersTypeSwitch.bind(this);
		this.confirmDeleteQuestion = this.confirmDeleteQuestion.bind(this);
		this.cloneQuestion = this.cloneQuestion.bind(this);
		this.cloneQuestionBelow = this.cloneQuestionBelow.bind(this);
		this.removeQuestion = this.removeQuestion.bind(this);
		this.addAnswer = this.addAnswer.bind(this);
		this.onCancelAnswerSwitch = this.onCancelAnswerSwitch.bind(this);
		this.onConfirmAnswerSwitch = this.onConfirmAnswerSwitch.bind(this);
		this.onMoveDown = this.onMoveDown.bind(this);
		this.onMoveUp = this.onMoveUp.bind(this);
		this.toggleJumpOnlyQuestion = this.toggleJumpOnlyQuestion.bind(this);
		this.onCollapseCard = this.onCollapseCard.bind(this);
		this.onVoteRangeChange = this.onVoteRangeChange.bind(this);
		this.handleAnswerValuesChange = this.handleAnswerValuesChange.bind(this);
		this.handleDropdownButtonClick = this.handleDropdownButtonClick.bind(this);
		this.toggleAnswerLimitEnabled = this.toggleAnswerLimitEnabled.bind(this);

		this.questionRef = React.createRef();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let newState = null;
		if (nextProps.question.questionId !== prevState.questionId) {
			newState = {};
			newState.questionReloaded = true;
		}
		if (nextProps.isCollapsedAll !== prevState.isOutsideCollapsed) {
			if (newState === null) {
				newState = {};
			}
			newState.isOutsideCollapsed = nextProps.isCollapsedAll;
		}
		return newState;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.isOutsideCollapsed !== this.state.isOutsideCollapsed) {
			this.setState({ showQuestionTitle: this.state.isOutsideCollapsed });
		}
		if (this.state.questionReloaded !== prevState.questionReloaded) {
			if (this.state.questionReloaded === true) {
				const questionInitialState = this.getQuestionInitialState(this.props.question);
				const reinitializedState = Object.assign({}, questionInitialState, {
					confirmAnswersTypeIndex: null,
					answerTypeSwitchConfirmVisible: false,
					isOutsideCollapsed: this.props.isCollapsedAll,
					questionReloaded: false
				});

				this.setState(reinitializedState);
			}
		}
		if (this.props.shouldAutoFocus) {
			this.questionRef.current.focus();
			this.props.onAutoFocus();
		}
	}

	getQuestionInitialState(question) {
		const voteInRangeDisabled = question.showForRange ? question.showForRange.length === 0 : true;
		return {
			questionId: question.questionId,
			voteInRangeDisabled,
			answersCount: question.answers.length,
			answersTypeIndex: this.getAnswerTypeIndexByName(question.answerSctructure),
			isMultipleOptionsEnabled: ANSWER_TYPES.indexOf(question.answerType) === 1,
			answerLimit: question.answerLimit ? question.answerLimit : 0,
			isJumpOnlyQuestion: question.isJumpOnly === true,
			showQuestionTitle: this.props.isCollapsedAll
		};
	}

	onValuesChange(changes = []) {
		const changesForUpdate = {};
		changes.forEach(c => changesForUpdate[c.field] = c.value);

		const updatedQuestion = Object.assign({}, this.props.question, changesForUpdate);

		this.props.onQuestionValuesChange(updatedQuestion);
	}

	onAnswersTypeSwitch(answersTypeIndex) {
		let shouldConfirmAnswerTypeSwitch = false;

		if (Number(answersTypeIndex) === 0) {
			shouldConfirmAnswerTypeSwitch = this.hasCloseEndedAnswers();
		} else if (Number(answersTypeIndex) === 1) {
			shouldConfirmAnswerTypeSwitch = this.hasOpenEndedAnswer();
		}

		if (!shouldConfirmAnswerTypeSwitch) {
			const newAnswer = Object.assign({}, EMPTY_ANSWER_TEMPLATE, { answerId: 1 });
			const changes = [];

			changes.push({ field: 'answerSctructure', value: ANSWERS_STRUCTURE[answersTypeIndex] });
			changes.push({ field: 'answers', value: [newAnswer] });

			this.onValuesChange(changes);
			this.setState({ answersTypeIndex: Number(answersTypeIndex) });
		} else {
			this.displayAnswerTypeSwitchConfirm(answersTypeIndex);
		}
	}

	onMoveDown() {
		this.setState({ isReordering: true, reorderDirection: 'down' });

		setTimeout(() => {
			this.sendReorderQuestion(this.props.question.orderIndex + 1);
		}, 500);
	}

	onMoveUp() {
		this.setState({ isReordering: true, reorderDirection: 'up' });

		setTimeout(() => {
			this.sendReorderQuestion(this.props.question.orderIndex - 1);
		}, 500);
	}

	onConfirmAnswerSwitch() {
		const { confirmAnswersTypeIndex } = this.state;
		const newAnswer = Object.assign({}, EMPTY_ANSWER_TEMPLATE, { answerId: 1 });
		const changes = [];

		changes.push({ field: 'answerSctructure', value: ANSWERS_STRUCTURE[confirmAnswersTypeIndex] });
		changes.push({ field: 'answers', value: [newAnswer] });

		this.onValuesChange(changes);

		this.setState({
			answerTypeSwitchConfirmVisible: false,
			answersTypeIndex: confirmAnswersTypeIndex
		});
	}

	onCancelAnswerSwitch() {
		this.setState({ answerTypeSwitchConfirmVisible: false });
	}

	sendReorderQuestion(newOrderIndex) {
		this.setState({
			isReordering: false,
			reorderDirection: null,
			isDirty: true
		});

		this.props.onReorderIndexChanged(this.props.question.questionId, newOrderIndex);
	}

	onVoteRangeChange(value) {
		this.context.setFieldValue(`${this.props.fieldPath}.showForRange`, value);
	}

	handleAnswerValuesChange(updatedAnswerValues) {
		const updatedAnswers = this.props.question.answers.map(a => {
			return a.answerId === updatedAnswerValues.answerId ? updatedAnswerValues : a;
		});

		const updatedQuestion = Object.assign({}, this.props.question, { answers: updatedAnswers });

		this.props.onQuestionValuesChange(updatedQuestion);
	}

	getAnswerTypeIndexByName(name) {
		const selectedAnswersStructureIndex = ANSWERS_STRUCTURE.indexOf(name);
		return selectedAnswersStructureIndex > -1 ? selectedAnswersStructureIndex : 0;
	}

	displayAnswerTypeSwitchConfirm(confirmAnswersTypeIndex) {
		this.setState({
			answerTypeSwitchConfirmVisible: true,
			confirmAnswersTypeIndex: Number(confirmAnswersTypeIndex)
		});
	}

	hasCloseEndedAnswers() {
		for (let i = 0; i < this.props.question.answers.length; i++) {
			if (this.props.question.answers[i].text.length > 0) {
				return true;
			}
		}

		return false;
	}

	hasOpenEndedAnswer() {
		if (this.props.question.answers[0]) {
			return this.props.question.answers[0].placeholderText.length > 0;
		}

		return false;
	}

	addAnswer() {
		const newAnswerId = this.getNewAnswerId([...this.props.question.answers]);
		const newAnswer = Object.assign({}, EMPTY_ANSWER_TEMPLATE, { answerId: newAnswerId });

		this.props.onAddAnswer(newAnswer, this.props.question.questionId);
	}

	getNewAnswerId(array) {
		const sortedAnswersByIdDesc = array.sort((a, b) => Number(b.answerId) - Number(a.answerId));
		return sortedAnswersByIdDesc.length > 0 ? sortedAnswersByIdDesc[0].answerId + 1 : 1;
	}

	toggleMultipleOptionsEnable() {
		const willMultipleOptionsEnabled = !this.state.isMultipleOptionsEnabled;
		if (!willMultipleOptionsEnabled) {
			this.context.setFieldValue(`${this.props.fieldPath}.answerType`, ANSWER_TYPES[0]);
		} else {
			this.context.setFieldValue(`${this.props.fieldPath}.answerType`, ANSWER_TYPES[1]);
		}
		this.setState({ isMultipleOptionsEnabled: willMultipleOptionsEnabled });
	}

	toggleAnswerLimitEnabled() {
		let newAnswerLimit;
		if (this.state.answerLimit > 0) {
			newAnswerLimit = 0;
		} else {
			newAnswerLimit = 3;
		}

		this.context.setFieldValue(`${this.props.fieldPath}.answerLimit`, newAnswerLimit);
		this.setState({ answerLimit: newAnswerLimit });
	}

	toggleVoteInRangeEnabled() {
		const willVoteInRangeBeDisabled = !this.state.voteInRangeDisabled;
		if (willVoteInRangeBeDisabled) {
			this.context.setFieldValue(`${this.props.fieldPath}.showForRange`, []);
		} else {
			this.context.setFieldValue(`${this.props.fieldPath}.showForRange`, [0, 10]);
		}
		this.setState({ voteInRangeDisabled: willVoteInRangeBeDisabled });
	}

	toggleJumpOnlyQuestion() {
		this.context.setFieldValue(`${this.props.fieldPath}.isJumpOnly`, !this.state.isJumpOnlyQuestion);
		this.setState({ isJumpOnlyQuestion: !this.state.isJumpOnlyQuestion });
	}

	handleDropdownButtonClick() {
		this.setState({ showDuplicateOptions: !this.state.showDuplicateOptions });
	}

	cloneQuestion() {
		this.props.onClone(this.props.question.questionId);
		this.setState({ showDuplicateOptions: false });
	}

	cloneQuestionBelow() {
		this.props.onClone(this.props.question.questionId, true);
		this.setState({ showDuplicateOptions: false });
	}

	confirmDeleteQuestion() {
		const t = this.state.t;

		if (!this.state.isDirty && this.props.question.question.length === 0) {
			this.removeQuestion();
		} else {
			const { question } = this.props;

			const modalConfig = {
				title: t('modal_qna_delete_title'),
				message: t('modal_qna_delete_message', { question: question.question }),
				onClose: () => this.context.hideModal(),
				onConfirm: this.removeQuestion,
			};

			this.context.showModal(CONFIRM_MODAL, modalConfig);
		}
	}

	removeQuestion() {
		this.context.hideModal();
		this.props.onDelete(this.props.question.questionId);
	}

	_getSliderMarks() {
		const marks = {};
		for (let index = 0; index <= 10; index++) {
			marks[index] = index.toString();
		}
		return marks;
	}

	onCollapseCard(isCardCollapsed) {
		this.setState({ showQuestionTitle: isCardCollapsed });
	}

	getCardActions() {
		return <span>
			{!this.props.isFirst &&
				<button type="button" className="btn btn-xs transparent animate animate-up" onClick={this.onMoveUp}><Icons.ArrowUp size={18} /></button>
			}

			{!this.props.isLast &&
				<button type="button" className="btn btn-xs transparent animate animate-down" onClick={this.onMoveDown}><Icons.ArrowDown size={18} /></button>
			}
		</span>;
	}

	get getQuestionTitle() {
		const currentQuestionNumber = this.props.questionIndex + 1;
		const t = this.state.t;

		let questionTitle = "";
		if (this.state.showQuestionTitle) {
			questionTitle = this.props.question.question;
		}

		return `${t('topic_builder_qna_question')} #${currentQuestionNumber} ${questionTitle}`;
	}

	getAnswersFromByType(index, arrayHelpers) {
		switch (index) {
			case 0:
				return this.openEndedAnswers(arrayHelpers);
			case 1:
				return this.closeEndedAnswers(arrayHelpers);
			case 2:
				return this.dropDownAnswers(arrayHelpers);
			case 3:
				return this.locationRequestAnswer(arrayHelpers);
			default:
				Logger.error(`No answers type option found for index ${index}`);
				break;
		}
	}

	openEndedAnswers() {
		return <AnswerItem
			key={0}
			answerIndex={0}
			answer={this.props.question.answers[0]}
			errors={this.props.errors}
			fieldPath={`${this.props.fieldPath}.answers.${0}`}
			answerType={'open'}
			canDelete={false}
			disableJumpQuestions={this.state.isMultipleOptionsEnabled}
			jumpQuestions={this.props.jumpQuestions}
			onAnswerValuesChange={this.handleAnswerValuesChange}
		/>;
	}

	closeEndedAnswers(arrayHelpers) {
		const t = this.state.t;
		const { fieldPath } = this.props;
		const { setFieldValue } = this.context;

		return <div className="closed-answers">
			<BSFormControl className="clearfix" style={{ marginBottom: '10px' }}>
				<Toggle value={this.state.isMultipleOptionsEnabled} onChange={this.toggleMultipleOptionsEnable} />
				<label>{t('topic_builder_qna_can_choose_multiple')}</label>
			</BSFormControl>
			{!this.state.isMultipleOptionsEnabled ? null :
				<BSFormControl className="clearfix">
					<Toggle value={this.state.answerLimit > 0} onChange={this.toggleAnswerLimitEnabled} />
					<label>Ограничи брой избрани отговори</label>
					{this.state.answerLimit === 0 ? null :
						<Field name={`${fieldPath}.answerLimit`}>
							{({ field }) => (
								<input
									type='number'
									className='form-control uitest-question'
									value={field.value}
									onChange={evt => setFieldValue(`${fieldPath}.answerLimit`, evt.target.value)}
									ref={this.questionRef}
									style={{ display: 'inline-block', width: '70px', marginLeft: '10px' }}
								/>
							)}
						</Field>
					}
				</BSFormControl>
			}

			{this._getMultiChoiceAnswers(arrayHelpers.remove)}

			<button
				type="button"
				className="btn btn-dotted btn-outline btn-success btn-full-width uitest-btn-add-answer"
				onClick={() => this.addAnswer(arrayHelpers.push)}
			>
				<Icons.PlusCircle size={16} /> {t('topic_builder_qna_answer_new')}
			</button>
		</div>;
	}

	dropDownAnswers() {
		return (
			<AnswerItem
				key={0}
				answerIndex={0}
				answer={this.props.question.answers[0]}
				errors={this.props.errors}
				fieldPath={`${this.props.fieldPath}.answers.${0}`}
				answerType={'dropdown'}
				canDelete={false}
				disableJumpQuestions={this.state.isMultipleOptionsEnabled}
				jumpQuestions={this.props.jumpQuestions}
				onAnswerValuesChange={this.handleAnswerValuesChange}
			/>
		);
	}

	locationRequestAnswer() {
		return (
			<AnswerItem
				key={0}
				answerIndex={0}
				answer={this.props.question.answers[0]}
				errors={this.props.errors}
				fieldPath={`${this.props.fieldPath}.answers.${0}`}
				answerType={'location_request'}
				canDelete={false}
				disableJumpQuestions={this.state.isMultipleOptionsEnabled}
				jumpQuestions={this.props.jumpQuestions}
				onAnswerValuesChange={this.handleAnswerValuesChange}
			/>
		);
	}

	_getMultiChoiceAnswers() {
		const { question } = this.props;
		const activeAnswers = question.answers;

		return activeAnswers.map((a, i) => {
			return <AnswerItem
				key={i}
				answerIndex={i}
				answer={a}
				errors={this.props.errors}
				fieldPath={`${this.props.fieldPath}.answers.${i}`}
				answerType={'close'}
				disableJumpQuestions={this.state.isMultipleOptionsEnabled}
				jumpQuestions={this.props.jumpQuestions}
				onRemoveAnswer={() => this.props.onRemoveAnswer(a.answerId, question.questionId)}
				onAnswerValuesChange={this.handleAnswerValuesChange}
				canDelete={this.props.question.answers.length > 1}
			/>;
		});
	}

	render() {
		const { question, fieldPath, errors } = this.props;
		const { setFieldValue } = this.context;
		const marks = this._getSliderMarks();
		const cardActions = this.getCardActions();

		const t = this.state.t;

		return (
			<div className={`uitest-qn-item question-item ${this.state.isReordering ? `animate animate-shrink animate-shrink-${this.state.reorderDirection}` : ''}`}>
				<Card
					collapsible
					isCollapsed={this.state.isOutsideCollapsed}
					onCollapsedChange={this.onCollapseCard}
					title={this.getQuestionTitle}
					cardActions={cardActions}
				>
					<div className='buttons-bar btn-group pull-right'>
						<button
							type='button'
							className='btn btn-xs btn-outline btn-danger top-right-question-button pull-right uitest-btn-delete'
							onClick={this.confirmDeleteQuestion}
						>
							<Icon.X size={12} /> {t('topic_builder_qna_question_delete')}
						</button>

						<div className="top-right-question-button">
							<div className={`${this.state.showDuplicateOptions ? 'dropdown-button-container' : ''} duplicate-question-button-container`}>
								<button
									type='button'
									className='btn btn-xs btn-outline pull-right dropdown-button'
									id="dropdownMenuButton"
									data-toggle="dropdown"
									aria-haspopup="true"
									onClick={this.handleDropdownButtonClick}
								>
									<Icon.Copy size={12} /> {t('topic_builder_qna_question_duplicate')} &nbsp; <Icon.ArrowDownCircle size={14} />
								</button>
								{this.state.showDuplicateOptions &&
									<ul className="dropdown-list-container" aria-labelledby="dropdownMenuButton">
										<li className="dropdown-list-item uitest-btn-clone-below btn btn-xs btn-outline" onClick={this.cloneQuestionBelow} ><Icon.ArrowDown size={12} />  {t('topic_builder_qna_question_duplicate_below')} </li>
										<li className="dropdown-list-item uitest-btn-clone btn btn-xs btn-outline" onClick={this.cloneQuestion} ><Icon.CornerRightDown size={12} />  {t('topic_builder_qna_question_duplicate_at_end')} </li>
									</ul>}
							</div>
						</div>

					</div>
					<BSFormControl required hasErrors={formValidators.fieldHasErrors(errors, `${fieldPath}.question`)}>
						<Field name={`${fieldPath}.question`}>
							{({ field }) => (
								<input
									type='text'
									className='form-control uitest-question'
									value={field.value}
									onChange={evt => setFieldValue(`${fieldPath}.question`, evt.target.value)}
									ref={this.questionRef}
								/>
							)}
						</Field>
						<ErrorMessage name={`${fieldPath}.question`} component="span" className='control-label' />
					</BSFormControl>

					<BSFormControl className="clearfix">
						<Field name={`${fieldPath}.showForRange`}>
							{({ field }) => {
								const currentRange = field.value ?
									<VoteRangeLabel low={field.value[0]} high={field.value[1]} /> : "";

								return (<div className="col-md-4">
									<Toggle value={!this.state.voteInRangeDisabled} onChange={this.toggleVoteInRangeEnabled} />
									<label>{t('topic_builder_qna_show_for_range')} {currentRange}</label>
								</div>);
							}}
						</Field>

						{!this.state.voteInRangeDisabled &&
							<div className="col-md-7 uitest-slider">
								<RangeSlider
									marks={marks}
									dots
									min={0}
									max={10}
									values={question.showForRange}
									onAfterChange={this.onVoteRangeChange}
								/>
								<div className="clearfix" />
								<small className="text-muted">{t('topic_builder_qna_vote_range_inclusive')}</small>
							</div>
						}
					</BSFormControl>

					<FeaturesContext
						requiredFeatures={[{ name: ENTITY_FEATURES.JUMP_QUESTIONS, compare: COMPARATORS.IS_TRUE }]}
						renderOtherwise={
							<BSFormControl className="clearfix">
								<div className="col-md-6">
									<NoPermissionLabel position="left" message={messages.permissions.features.noCustomTopicImage} />
									<Toggle value={false} disabled={true} />
									<label>{t('topic_builder_qna_jump_only')}</label>
								</div>
							</BSFormControl>
						}
					>
						<BSFormControl className="clearfix">
							<div className="col-md-6">
								<Toggle value={this.state.isJumpOnlyQuestion} onChange={this.toggleJumpOnlyQuestion} />
								<label>{t('topic_builder_qna_jump_only')}</label>
								<div className="clearfix" />
								<small className="text-muted">{t('topic_builder_qna_jump_only_info')}</small>
							</div>
						</BSFormControl>
					</FeaturesContext>

					<h4>Снимка към въпроса</h4>

					{!this.props.topicId ?
						<p style={{ margin: '10px 0 30px 10px' }}>
							Снимка може да се прикачва към въпрос само на вече публикувана тема.
						</p> :
						<MediaDnD
							model={this.props.model}
							previewField={`${this.props.fieldPath}.mediaUrl`}
							legacyField={`${this.props.fieldPath}.image`}
							uploadImageEndpoint={`/topics/${this.props.topicId}/upload_images/qna`}
							uploadVideoEndpoint={`/topics/${this.props.topicId}/upload_video/qna`}
							imageProcessor={processQuestionImage}
							setFieldValue={this.context.setFieldValue}
							setFieldError={this.context.setFieldError}
							fieldNames={[`${this.props.fieldPath}.mediaUrl`]}
						/>
					}

					<hr />

					{this.state.answerTypeSwitchConfirmVisible === false ?
						<ButtonGroup
							buttons={[
								t('topic_builder_qna_open_ended'),
								t('topic_builder_qna_multiple_choice'),
								t('topic_builder_qna_dropdown'),
								t('topic_builder_qna_location_request')
							]}
							indexOfSelected={this.state.answersTypeIndex}
							onAfterChange={this.onAnswersTypeSwitch}
						/>
						:
						// extract to component 
						<p className="message alert alert-warning uitest-answer-switch-warning">
							{t('topic_builder_qna_data_warning')}
							<button className="btn btn-link text-danger" onClick={this.onConfirmAnswerSwitch}>&nbsp;&nbsp;<Icons.Check size={14} />{t('label_yes')}</button> &nbsp;|&nbsp; <button className="btn btn-link text-muted" onClick={this.onCancelAnswerSwitch}><Icons.X size={14} />{t('label_no')}</button>
						</p>
					}

					<FieldArray name={`${fieldPath}.answers`} render={arrayHelpers => (
						<div className="answer-items">
							{
								this.getAnswersFromByType(this.state.answersTypeIndex, arrayHelpers)
							}
						</div>
					)} />
				</Card>

			</div>
		);
	}
}

QuestionItem.contextTypes = {
	showModal: PropTypes.func,
	hideModal: PropTypes.func,
	setFieldValue: PropTypes.func,
	setFieldError: PropTypes.func,
	setValues: PropTypes.func
};

QuestionItem.propTypes = {
	question: PropTypes.shape({
		questionId: PropTypes.number.isRequired,
		orderIndex: PropTypes.number,
		question: PropTypes.string,
		showForRange: PropTypes.arrayOf(PropTypes.number),
		isJumpOnly: PropTypes.bool,
		answerSctructure: PropTypes.string,
		answerType: PropTypes.string,
		answers: PropTypes.arrayOf(PropTypes.shape({
			answerId: PropTypes.number.isRequired,
			text: PropTypes.string,
			placeholderText: PropTypes.string,
			nextQuestionId: PropTypes.number,
			link: PropTypes.string,
			isLink: PropTypes.bool,
			canAttachImage: PropTypes.bool,
		})).isRequired
	}).isRequired,

	errors: PropTypes.object,

	fieldPath: PropTypes.string.isRequired,

	/** @prop {number} questionIndex the current question index */
	questionIndex: PropTypes.number.isRequired,

	isLast: PropTypes.bool,
	isFirst: PropTypes.bool,

	/** @prop {array} jumpQuestions array of questions to select an answer jump question */
	jumpQuestions: PropTypes.array.isRequired,

	onQuestionValuesChange: PropTypes.func,

	/** @prop {func} onClone clone question action */
	onClone: PropTypes.func.isRequired,

	/** @prop {func} onDelete send delete question action */
	onDelete: PropTypes.func.isRequired,

	/** @prop {func} onReorderIndexChanged action handler to set a new order index for the current question */
	onReorderIndexChanged: PropTypes.func.isRequired,

	/** @prop {bool} isCollapsedAll controll property to set all items as collapsed */
	isCollapsedAll: PropTypes.bool,

	onAddAnswer: PropTypes.func.isRequired,
	onRemoveAnswer: PropTypes.func.isRequired,

	/** @prop {bool} shouldAutoFocus determines whether the quiestionItem cart should grasp the focus */
	shouldAutoFocus: PropTypes.bool,
	/** @prop {func} onAutoFocus action triggered when the focus is set */
	onAutoFocus: PropTypes.func
};

export default QuestionItem;
