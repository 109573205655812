import React, { Component } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import PropTypes from 'prop-types';
import { Clipboard, Check, Download } from 'react-feather';
import QRCode from 'qrcode.react';

import Card from '../common/card';
import BSFormControl from '../common/form/bsFormControl';

import { getVoterPreviewUrl } from '../../helpers/utils';
import { LINK_SOURCES_MAX_COUNT } from '../../helpers/topicBuilderHelper';
import { isOnMobile } from '../../helpers/utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './css/kazvabg-rating-widget-animation.css';

import i18n from '../../i18n';

const QR_IMAGE_SIZE_OPTIONS = [
	{ label: '1024x1024px', value: 1024 },
	{ label: '512x512px', value: 512 },
	{ label: '256x256px', value: 256 },
	{ label: '128x128px', value: 128 }
];

const QR_DOWNLOAD_FRAME_RATIO = 0.06;
const QR_DOWNLOAD_INITIAL_SIZE = 256;
const QR_DOWNLOAD_MINIMAL_SIZE_WITH_LOGO = 256;

class TopicMediaEmbed extends Component {
	constructor(props) {
		super(props);
		this.state = {
			urlCopied: false,
			htmlSnippetCopied: false,
			selectedImageSizeOption: QR_DOWNLOAD_INITIAL_SIZE,
			selectedLinkSource: '',
			t: i18n.t.bind(i18n),
		};

		this.mediaKit = React.createRef();

		this.selectedQRsize = QR_DOWNLOAD_INITIAL_SIZE * (1 - 2 * QR_DOWNLOAD_FRAME_RATIO);

		this.fillBackground = this.fillBackground.bind(this);
		this.fillQRinCanvas = this.fillQRinCanvas.bind(this);
		this.fillLogoInQR = this.fillLogoInQR.bind(this);

		this.handleImageSizeSelection = this.handleImageSizeSelection.bind(this);
		this.handleDownloadClick = this.handleDownloadClick.bind(this);
		this.setStateCopied = this.setStateCopied.bind(this);
		this.handleLinkSourceSelection = this.handleLinkSourceSelection.bind(this);
		this.resetQRCodePreviews = this.resetQRCodePreviews.bind(this);
		this.qrImg = document.createElement('img');
		this.handleQrImageLoad = this.handleQrImageLoad.bind(this);
	}

	componentDidMount() {
		this.resetQRCodePreviews();
		this.mediaKit.current.getElementsByClassName('html-snippet-preview')[0].value = this.getHtmlSnippetCode();
		this.qrImg.addEventListener('load', this.handleQrImageLoad);
	}

	componentWillUnmount() {
		this.qrImg.removeEventListener('load', this.handleQrImageLoad);
	}

	handleQrImageLoad(event) {
		const sizeCanvas = this.state.selectedImageSizeOption;
		const sizeQRtoDownload = this.generatedQRcodeCanvas.width;
		const position = this.QRbackgroundFrameWidth;
		this.contextCanvasToDownload.drawImage(
			event.target,
			0,
			0,
			sizeQRtoDownload,
			sizeQRtoDownload,
			position,
			position,
			this.selectedQRsize,
			this.selectedQRsize
		);
		if (sizeCanvas >= QR_DOWNLOAD_MINIMAL_SIZE_WITH_LOGO) {
			this.fillLogoInQR(this.fullCanvasToDownload);
		}
	}

	get QRbackgroundFrameWidth() {
		return QR_DOWNLOAD_FRAME_RATIO * this.state.selectedImageSizeOption;
	}

	handleDownloadClick() {
		const link = document.createElement('a');

		// polyfill for Edge support
		if (!this.fullCanvasToDownload.toBlob) {
			Object.defineProperty(this.fullCanvasToDownload, 'toBlob', {
				value: (callback) => {
					var dataURL = this.dataCanvasToDownload.split(',')[1];
					setTimeout(function () {
						var binaryString = atob(dataURL),
							binaryArray = new Uint8Array(binaryString.length);

						for (var i = 0; i < binaryString.length; i++) {
							binaryArray[i] = binaryString.charCodeAt(i);
						}

						callback(new Blob([binaryArray], { type: 'image/png' }));
					});
				}
			});
		}

		this.fullCanvasToDownload.toBlob((blobToDownload) => {
			link.href = window.URL.createObjectURL(blobToDownload);
			const linkSourcesString = this.state.selectedLinkSource ? this.state.selectedLinkSource.split(',').reduce((acc, cv) => `${acc}-${cv}`, '') : '';
			link.download = `${this.props.topicName}${linkSourcesString}_${this.state.selectedImageSizeOption}px_qr.png`;

			const evt = new MouseEvent('click', {
				view: window
			});

			link.dispatchEvent(evt);
		});
	}

	handleImageSizeSelection(ev) {
		this.setState({ selectedImageSizeOption: Number(ev.target.value) }, this.fillCanvasForDownload);
	}

	handleLinkSourceSelection(ev) {
		this.setState({ selectedLinkSource: ev.target.value }, this.resetQRCodePreviews);
	}

	resetQRCodePreviews() {
		this.fillLogoInQR(this.canvasQRPreview, 192);
		this.fillCanvasForDownload();
	}

	fillCanvasForDownload() {
		this.fillBackground();
		this.fillQRinCanvas();
	}

	fillBackground() {
		this.contextCanvasToDownload.fillStyle = '#FFFFFF';
		this.contextCanvasToDownload.fillRect(
			0,
			0,
			this.fullCanvasToDownload.width,
			this.fullCanvasToDownload.height
		);
	}

	fillQRinCanvas() {
		this.qrImg.src = this.qrData;
	}

	fillLogoInQR(canvasToFill, spaceToFillSize) {
		const position = spaceToFillSize ? spaceToFillSize * 0.25 : canvasToFill.width * 0.25;

		const logo = document.createElement('img');
		const canvasSize = spaceToFillSize ? spaceToFillSize : this.state.selectedImageSizeOption;
		logo.src = this.getLogoData(canvasSize);

		logo.onload = () => {
			canvasToFill.getContext('2d').drawImage(
				logo,
				position,
				position);
		};
	}

	get fullCanvasToDownload() {
		return document.getElementById('imageToDownload');
	}

	get generatedQRcodeCanvas() {
		return document.getElementById('QRcodeToFill');
	}

	get canvasQRPreview() {
		return document.getElementById('QRPreview');
	}

	get contextCanvasToDownload() {
		return document.getElementById('imageToDownload').getContext('2d');
	}

	get dataCanvasToDownload() {
		return document.getElementById('imageToDownload').toDataURL('image/png');
	}

	get qrData() {
		return document.getElementById('QRcodeToFill').toDataURL('image/png');
	}

	getLogoData(sizeCanvas) {
		switch (sizeCanvas) {
			case 128:
				return '/kazva-transparent-64px.png';
			case 192:
				// used in qr-preview only:
				return '/kazva-transparent-96px.png';
			case 256:
				return '/kazva-transparent-128px.png';
			case 512:
				return '/kazva-transparent-256px.png';
			case 1024:
				return '/kazva-transparent-512px.png';
			default:
				return null;
		}
	}

	getImageSizeOptions() {
		return QR_IMAGE_SIZE_OPTIONS.map((o, i) => <option key={i} value={o.value}>{o.label}</option>);
	}

	getLinkSourceOptions() {
		return this.props.linkSources.map((ls, i) => <option key={i} value={ls.value}>
			{ls.value}
		</option>);
	}

	setStateCopied(copied) {
		switch (copied) {
			case 'urlCopied':
				this.setState({ urlCopied: true, htmlSnippetCopied: false });
				break;
			case 'htmlSnippetCopied':
				this.setState({ htmlSnippetCopied: true, urlCopied: false });
				break;
			default:
				break;
		}
	}

	htmlSnippetTemplate(topicName, topicUrl) {
		const topicUrlWithSource = this.appendEmbeddedHtmlSource(topicUrl);
		//const imgHollerBotfaceSrc = `${this.getHollerBotface()}#${topicName}_${Date.now()}`;

		return (
			<div className="kazvabg-rating-widget" style={{
				display: 'flex',
				width: '313px',
				padding: '16px',
				flexDirection: 'column',
				alignItems: 'flex-start',
				gap: '16px',
				borderRadius: '16px',
				border: '1px solid #e6e6e6',
				background: '#fff',
				boxSizing: 'border-box'
			}}>
				<div className="kazvabg-rating-widget__title" style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
					gap: '3.86px',
					alignSelf: 'stretch',
					fontFamily: 'Inter, Helvetica, Arial, sans-serif',
					fontSize: '16px',
					fontWeight: 500,
					lineHeight: '150%',
					textAlign: 'left'
				}}
				>
					{topicName}
				</div>
				<ul className="kazvabg-rating-widget__features" style={{
					margin: 0,
					padding: 0,
					listStyleType: 'none', 
					display: 'flex',
					flexWrap: 'nowrap',
					alignItems: 'center',
					gap: '8px'
				}}>
					<li className="kazvabg-rating-widget__feature" style={{
						display: 'flex',
						alignItems: 'center',
						whiteSpace: 'nowrap',
						gap: '2.574px',
						fontFamily: 'Inter, Helvetica, Arial, sans-serif',
						fontSize: '12px',
						fontStyle: 'normal',
						fontWeight: 400,
						lineHeight: '150%'
					}}
					>
						<div className="kazvabg-rating-widget__icon" style={{
							width: '10px',
							height: '10px', 
							display: 'flex',
							alignItems: 'center'
						}}>
							<img src="https://dashboard.kazva.bg/embed/embed-checkmark-icon.png" alt="Embed checkmark icon" style={{width: '100%', height: '100%', objectFit: 'cover'}} />
						</div>
						За секунди
					</li>
					<li className="kazvabg-rating-widget__feature" style={{
						display: 'flex',
						alignItems: 'center',
						whiteSpace: 'nowrap',
						gap: '2.574px',
						fontFamily: 'Inter, Helvetica, Arial, sans-serif',
						fontSize: '12px',
						fontStyle: 'normal',
						fontWeight: 400,
						lineHeight: '150%'
					}}>
						<div className="kazvabg-rating-widget__icon" style={{
							width: '10px',
							height: '10px',
							display: 'flex',
							alignItems: 'center'
						}}>
							<img src="https://dashboard.kazva.bg/embed/embed-checkmark-icon.png" alt="Embed checkmark icon" style={{width: '100%', height: '100%', objectFit: 'cover'}} />
						</div>
						Анонимно
					</li>
					<li className="kazvabg-rating-widget__feature" style={{
						display: 'flex',
						alignItems: 'center',
						whiteSpace: 'nowrap',
						gap: '2.574px',
						fontFamily: 'Inter, Helvetica, Arial, sans-serif',
						fontSize: '12px',
						fontStyle: 'normal',
						fontWeight: 400,
						lineHeight: '150%'
					}}>
						<div className="kazvabg-rating-widget__icon" style={{
							width: '10px',
							height: '10px',
							display: 'flex',
							alignItems: 'center'
						}}>
							<img src="https://dashboard.kazva.bg/embed/embed-checkmark-icon.png" alt="Embed checkmark icon" style={{width: '100%', height: '100%', objectFit: 'cover'}} />
						</div>
						Без регистрация
					</li>
				</ul>
				<div className="kazvabg-rating-widget__button-container" style={{display: 'flex'}}>
					<div className="kazvabg-rating-widget__button-wrapper" style={{
						position: 'absolute',
						display: 'flex',
						alignItems: 'flex-start',
						width: '115px',
						height: '42px',
						borderRadius: '50px',
						justifyContent: 'center',
						alignItems: 'center',
						boxShadow: '0px 2.839px 8.518px 0px rgba(0, 0, 0, 0.1)',
						backgroundColor: 'white',
						overflow: 'visible'
					}}>
						<a 
							href={topicUrlWithSource} 
							target="_blank" 
							className="kazvabg-rating-widget__button"
							style={{
								position: 'absolute',
								cursor: 'pointer', 
								width: '112px',
								height: '39px',
								backgroundColor: 'white',
								color: 'black',
								border: 'none',
								borderRadius: '50px',
								fontWeight: 'bold',
								zIndex: 2,
								display: 'inline-block',
								textDecoration: 'none',
								fontFamily: 'Inter, Helvetica, Arial, sans-serif',
								textAlign: 'center',
								verticalAlign: 'middle',
								paddingTop: '9px',
								boxSizing: 'border-box'
							}}
						>
							Оценете
						</a>
						<div 
							className="kazvabg-rating-widget__gradient"
							style={{
								position: 'absolute',
								width: '100%',
								height: '100%',
								clipPath: 'inset(0 round 50px)',
								background: 'linear-gradient(#20e534 0%, #1ed931 10%, #53b7ff 30%, #538cff 50%, #c36eff 70%, #ff4d40 90%, #ff4133 100%)'
							}}
						/>
					</div>
				</div>
			</div>
		);
	}

	getHollerBotface() {
		const env = process.env.REACT_APP_DEPLOY_ENV || 'localdev';

		switch (env) {
			case 'production': {
				return "https://img.holler.live/images/logo_anim_smiley.gif";
			}
			case 'staging': {
				return "https://imgs.holler.live/images/logo_anim_smiley.gif";
			}
			case 'localdev': {
				return "https://imgs.holler.live/images/logo_anim_smiley.gif";
			}
			default: {
				return "https://img.holler.live/images/logo_anim_smiley.gif";
			}
		}
	}

	getHtmlSnippetCode() {
		return document.getElementById('html-snippet-container').innerHTML;
	}

	appendQrcodeSource(topicUrl) {
		const sourcesCount = (topicUrl.match(/[?&]s=/g) || []).length;
		const defaultSourcePresent = (topicUrl.match(/[?&]s=qrcode/g) || []).length > 0;
		if (sourcesCount < LINK_SOURCES_MAX_COUNT && !defaultSourcePresent) {
			if (sourcesCount === 0) {
				return `${topicUrl}?s=qrcode`;
			}
			return `${topicUrl}&s=qrcode`;
		}
		return topicUrl;
	}

	appendEmbeddedHtmlSource(topicUrl) {
		const sourcesCount = (topicUrl.match(/[?&]s=/g) || []).length;
		if (sourcesCount === 0) {
			return `${topicUrl}?s=embeddedSnippet`;
		}
		return `${topicUrl}&s=embeddedSnippet`;
	}

	render() {
		const { topicName, topicDisplayName } = this.props;

		const topicNameLabel = topicDisplayName && topicDisplayName.length > 0 ? topicDisplayName : topicName;
		const topicUrl = getVoterPreviewUrl(topicName, this.state.selectedLinkSource);
		const topicQrUrl = this.appendQrcodeSource(topicUrl);
		this.selectedQRsize = this.state.selectedImageSizeOption - (this.QRbackgroundFrameWidth * 2);

		const linkTagHtml = `<link rel="stylesheet" href="https://dashboard.kazva.bg/embed/kazvabg-rating-widget-animation.css">`;

		return (
			<div className="topic-links-modal-content" ref={this.mediaKit}>
				<p className="text-label text-light">
					{this.state.t('topic_embed_view_topic')} <strong>{`"${topicNameLabel}"`}</strong><br />
					{this.state.t('topic_embed_view_topic_mobile')}
				</p>

				{this.props.linkSources && this.props.linkSources.length > 0 &&
					<form className="form-horizontal">
						<div className="formGroup row-fluid clearfix">
							<div className="col-md-9">
								<BSFormControl labelColWidth={5} label={this.state.t('topic_embed_link_source')} horizontal>
									<select className="form-control uitest-linksource-select" value={this.state.selectedLinkSource} onChange={this.handleLinkSourceSelection} >
										<option value=''>{this.state.t('topic_embed_link_no_sources')}</option>
										{this.getLinkSourceOptions()}
									</select>
								</BSFormControl>
							</div>
						</div>
					</form>
				}

				<Card>
					<QRCode id="QRPreview" size={192} value={topicQrUrl} level='Q' className="uitest-qrcode" />
				</Card>

				<form className="form-horizontal">
					<div className="form-group row-fluid clearfix">
						<div className="col-md-7">
							<BSFormControl labelColWidth={7} label={this.state.t('topic_embed_image_size')} horizontal>
								<select className="form-control" value={this.state.selectedImageSizeOption} onChange={this.handleImageSizeSelection}>
									{this.getImageSizeOptions()}
								</select>
							</BSFormControl>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								<button type="button" className="btn btn-link download uitest-btn-download" onClick={this.handleDownloadClick}><Download size={18} /> {this.state.t('topic_embed_download')}</button>
							</div>
						</div>
					</div>

					<p className="text-label text-light">{this.state.t('topic_embed_or_open_in_browser')}</p>
					<a className="topic-url text-black" href={`${topicUrl}`} target="_blank" rel="noopener noreferrer">{topicUrl}</a>
					<CopyToClipboard text={topicUrl}
						onCopy={() => this.setStateCopied('urlCopied')}>
						<button type="button" className="btn btn-link copy-to-clipboard text-light text-label">
							<Clipboard size={14} /> {this.state.t('topic_embed_copy_url')}
							{this.state.urlCopied && <Check size={12} />}
						</button>
					</CopyToClipboard>


					<div className="embeddable-html-snippet">
						<p className="text-label text-light">{this.state.t('topic_embed_embeddable_snippet')}</p>
						<Card className="row-fluid clearfix html-snippet-card">
							<div className={`col-md-6 ${isOnMobile() ? 'col-xs-12' : ''}`}>
								<label className="text-label font-weight-bold">{this.state.t('topic_embed_embeddable_preview')}</label>
								<div id="html-snippet-container">
									{this.htmlSnippetTemplate(topicNameLabel, topicUrl)}
								</div>
							</div>

							<div className={`col-md-6 ${isOnMobile() ? 'col-xs-12' : ''}`}>
								<label className="text-label font-weight-bold">{this.state.t('topic_embed_embeddable_html_code')}</label>
								<CopyToClipboard text={renderToStaticMarkup(this.htmlSnippetTemplate(topicNameLabel, topicUrl))}
									onCopy={() => this.setStateCopied('htmlSnippetCopied')}>
									<button type="button" className="btn btn-link copy-to-clipboard text-light text-label">
										<Clipboard size={14} /> {this.state.t('topic_embed_copy_snippet')}
										{this.state.htmlSnippetCopied && <Check size={12} />}
									</button>
								</CopyToClipboard>

								<div className="html-snippet-preview-box">
									<textarea
										className="html-snippet-preview"
										type="text"
										disabled={true}
									/>
								</div>
							</div>
							<br/>
							<div className='col-md-12' style={{marginTop: '10px', textAlign: 'left'}}>
								<label className="text-label font-weight-bold">{this.state.t('topic_embed_animation_title')}</label>
								<CopyToClipboard text={linkTagHtml}
									onCopy={() => this.setStateCopied('htmlSnippetCopied')}>
									<button type="button" className="btn btn-link copy-to-clipboard text-light text-label">
										<Clipboard size={14} /> {this.state.t('topic_embed_copy_snippet')}
										{this.state.htmlSnippetCopied && <Check size={12} />}
									</button>
								</CopyToClipboard>
								<p>{this.state.t('topic_embed_animation_description')}</p>
								<div className="html-snippet-preview-box">
									<textarea
										style={{ width: '100%' }}
										className="html-snippet-preview"
										type="text"
										disabled={true}
										value={linkTagHtml}
									/>
								</div>
							</div>
						</Card>
					</div>


					<div id="generatedQRtoDownload" className="hidden-element">
						<canvas id="imageToDownload" width={this.state.selectedImageSizeOption} height={this.state.selectedImageSizeOption} />
						<QRCode id="QRcodeToFill" value={topicQrUrl} size={this.selectedQRsize} width={this.selectedQRsize} height={this.selectedQRsize} level='Q'
							style={{ width: `${this.selectedQRsize}px`, height: `${this.selectedQRsize}px` }} className="uitest-qrcode-download" />
					</div>
				</form>
			</div>
		);
	}
}

TopicMediaEmbed.propTypes = {
	topicName: PropTypes.string.isRequired,
	topicDisplayName: PropTypes.string,
	linkSources: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		value: PropTypes.string
	}))
};

export default TopicMediaEmbed;